import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom'
import { getCustomerAction } from '../Redux/Action/UserAction';
import { Encryptedid } from '../Util/BcruptEncyptid';
import Sidebar from './Include/Sidebar';
import moment from 'moment';
import Pagination from '../Component/Pagination';
import { useState } from 'react';



export default function AllUsers() {
  const dispatch=useDispatch();
const [pagiantionselected,setPagination]=useState();
  useEffect(()=>{
      dispatch(getCustomerAction())
  },[])

  var userlist=useSelector((state)=>state.User.user)


  const navigate=useNavigate();

  var userprofile =useCallback( (id) => {
      const encodedEncrypted = Encryptedid(id);
      navigate("/Customer-overview/"+encodedEncrypted);
    },[]);

const handlePageClick=(selected)=>{
  setPagination(selected.selected+1)
  console.log(selected.selected+1,"llllllllll")
  dispatch(getCustomerAction({
    pageno:selected.selected+1
  }))

}



  return (
    <div>
       {/* <!-- Begin page --> */}
        <div id="layout-wrapper">

            
            {/* <!-- ========== Left Sidebar Start ========== --> */}
            <div className="vertical-menu">

                <div data-simplebar="" className="h-100">

                 <Sidebar />
                    
                </div>
            </div>
            {/* <!-- Left Sidebar End --> */}

            

            {/* <!-- ============================================================== -->
            <!-- Start right Content here -->
            <!-- ============================================================== --> */}
            <div class="main-content">

                <div class="page-content">
                    <div class="container-fluid">

                        {/* <!-- start page title --> */}
                        <div class="row">
                            <div class="col-12">
                                
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                   <div>
                                          <span class="small_text">Consultify</span>
                                          <div className='d-flex justify-content-center align-items-center'>
                                               <h4 class="mb-sm-0 font-size-28">All Users</h4>
                                          <p className='total'>{userlist?.length}</p>
                                          </div>
                                         </div>
                                     <div class="page-title-right">
                                    {/* <!-- App Search--> */}
                                           <img src={require('../assets/images/avatar.svg').default} />
                                      </div>
                                  </div>
                            </div>
                        </div>
                          {/* <!-- end page title --> */}
                          

                           {/* <!-- start search row --> */}
                       <div class="row mb-4">
                            <div class="col-xxl-4 col-lg-3">
                            <input type="text" class="form-control cmn_fields" id="" aria-describedby="emailHelp" placeholder="Search Here" />
                              </div>    

                            <div className="col-xxl-2 col-lg-3">  
                                    <div class="form-group">
                                    <select class="form-control cmn_fields" id="exampleFormControlSelect1">
                                    <option>Type</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    </select>
                                  </div>
                                  </div>
                                      
                               <div className="col-xxl-2 col-lg-3"> 
                                      <div class="form-group">
                                    <select class="form-control cmn_fields" id="exampleFormControlSelect2">
                                    <option>Last Active</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    </select>
                                  </div>
                                  </div>
                                      
                               <div className="col-xxl-2 col-lg-3"> 
                                       <div class="form-group">
                                    <select class="form-control cmn_fields" id="exampleFormControlSelect3">
                                    <option>Purchases</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    </select>
                                  </div>
                              </div>
                              
                              
                            </div>
                        </div>
                        {/* <!-- end search row --> */}

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="members_tbl">

                                    <div class="card">
                                        <div class="card-body">

                                            <div class="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                                                <h4 class="title_text">Users</h4>
                                            </div>
            
            
                                            <div class="table-responsive">
                                                <table id="" class="table dt-responsive dealers_table nowrap w-100">
                                                    <thead>
                                                    <tr>
                                                        <th>Customer ID</th>
                                                        <th>Customer Name</th>
                                                        <th>Date Joined <img class="filt_arrow" src={require('../assets/images/greydownarrow.svg').default}  /></th>
                                                        <th>Contact Email</th>
                                                        <th>Contact Phone </th>
                                                        <th>City</th>
                                                        <th>Last Active <img class="filt_arrow" src={require('../assets/images/greydownarrow.svg').default}  /></th>
                                                        <th>Consultations </th>
                                                    </tr>
                                                    </thead>
                                                   <tbody class="td_color">
                                                   {
                                                    userlist?.datalist?.map(function(user_object,i){
                                                      return(
                                                        <tr key={i}>
                                                        <td>{i+1}</td>
                                                        <td><span className='cursor-pointer' onClick={()=>{
                                                          userprofile(user_object._id)
                                                        }}>{user_object.first_name+" "+user_object.last_name} </span></td>
                                                        <td>{moment(user_object.updatedAt).format("DD/MM/YYYY")}</td>
                                                        <td>{user_object.email}</td>
                                                        <td>{user_object.phone_number}</td>
                                                        <td>{user_object.city}</td>
                                                        <td>{moment(user_object.lastLogin).format("DD/MM/YYYY")}</td>
                                                        <td>---</td>
                                                      </tr>
                                                    
                                                      )
                                                    })
                                                   }
                                                    
                                                    </tbody>
                                                </table>
                                            </div>
                                           
                                            {/* <Pagination  handlePageClick={handlePageClick} pageCounttotal={userlist?.totalpagecount==undefined?0:userlist?.totalpagecount} />
                                     
                                            */}
            
                                        </div>
                                    </div>

                              
                                </div>
                            </div>

                        
                        </div>
                        
                      </div>
                      {/* <!-- container-fluid --> */}
                </div>
                {/* <!-- End Page-content --> */}

        
                

            </div>
            {/* <!-- end main content--> */}

      </div>
  )
}


       

       

       
