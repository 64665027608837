import React, { useCallback, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Sidebar from "./Include/Sidebar";
import { Decryptedid, Encryptedid } from "../Util/BcruptEncyptid";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomerResetpasswordAction,
  customerDetailsAction,
  deleteUserAction,
  updateaccountstatusAction,
  updateuserdetailsAction,
} from "../Redux/Action/UserAction";
import moment from "moment";
import { updateSalondetailsAction } from "../Redux/Action/SalonAction";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import RichTextEditor from "../Component/RichTextEditor/RichTextEditor";
export default function EditFaq() {
  return (
    <div>
      {/* <!-- Begin page --> */}
      <div id="layout-wrapper">
        {/* <!-- ========== Left Sidebar Start ========== --> */}
        <div className="vertical-menu">
          <div data-simplebar="" className="h-100">
            {/* <!--- Sidemenu --> */}
            <Sidebar />
            {/* <!-- Sidebar --> */}
          </div>
        </div>
        {/* <!-- Left Sidebar End --> */}

        {/* <!-- ============================================================== -->
         <!-- Start right Content here -->
         <!-- ============================================================== --> */}
        <div className="main-content">
          <div className="container-fluid">
            <div className="create_tab">
              {/* <!-- start page title --> */}

              <div className="row align-items-center">
                <div className="col-6 d-flex align-items-center">
                  <NavLink to="/faqs">
                    <img src={require("../assets/images/Component.png")} />
                  </NavLink>
                  <h4 className="mb-sm-0 font-size-28">Edit FAQ</h4>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="members_tbl">
                  <div className="card ">
                    <div className="card-body">
                      <div className="row">
                        <div className="faqs-main">
                          <div className="col-lg-12">
                            <label>Assigned To</label>
                            <select
                              class="form-select"
                              aria-label="Default select example"
                            >
                              <option selected>Open this select menu</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div className="col-lg-12">
                            <label>Title</label>
                            <input
                              type="text"
                              class="form-control"
                              id=""
                              placeholder="Type Here...."
                            />
                          </div>
                          <div className="col-lg-12">
                            <label>Content</label>
                            <RichTextEditor />
                          </div>
                          <div className="col-lg-12">
                            <div className="d-flex justify-content-center align-items-center flex-wrap mb-3 position-relative">
                              <button type="submit" className="new_update">
                                Edit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- container-fluid --> */}
        </div>
        {/* <!-- End Page-content --> */}
      </div>
    </div>
  );
}
